<template>
  <div class="search-field__container search-section">
    <b-form-input
      v-if="showInputField"
      v-model="Search.searchText"
      :placeholder="placeHolder"
      type="search"
      :autofocus="true"
      autocomplete="off"
    />
  </div>
</template>

<script>
import { BFormInput } from 'bootstrap-vue';
import Search from '@/Services/Search';

export default {
  components: {
    BFormInput,
  },
  directives: {
    'b-form-input': BFormInput,
  },
  props: {
    placeHolderText: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      Search,
      showInputField: false,
    };
  },
  computed: {
    placeHolder() {
      if (this.placeHolderText === '') return this.$t('Components.Search.placeholder');
      return this.placeHolderText;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'Search.searchText': function () {
      Search.sortResults();
    },
  },
  created() {
    Search.searchText = '';
  },
  mounted() {
    // Such a silly solution, but there seems to be some race condition going on with Vue create/mount stages
    // So Bootstrap :autofocus doesn't work unless we delay it by 0 in this case, just to let Vue cycle finish
    setTimeout(() => { this.showInputField = true; }, 0);
  },
};
</script>
<style lang="scss" scoped>
@import '../style/search.scss';

</style>
