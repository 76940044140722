<template>
  <div
    v-if="searchResults"
    class="search-results__container search-section"
  >
    <Card
      v-if="users && searchResults?.Users?.length && Search.searchText.length > 0"
      position="bottom no-padding-top"
      :header="$t('Components.Search.users')"
    >
      <div
        v-for="result in searchResults.Users"
        :key="result.Username"
      >
        <SearchResult
          v-if="shouldShow(result)"
          :result="result"
          @onResultClick="bubbleUp"
          @pinResource="pinResource"
        />
      </div>
    </Card>

    <Card
      v-if="resources && searchResults?.Zones?.length && Search.searchText.length > 0"
      position="bottom no-padding-top"
      :header="$t('Components.Search.resources')"
    >
      <div
        v-for="result in searchResults.Zones"
        :key="result.Zid"
      >
        <SearchResult
          v-if="shouldShow(result)"
          :result="result"
          @onResultClick="bubbleUp"
          @pinResource="pinResource"
        />
      </div>
    </Card>

    <Card
      v-if="users && resources && Search.searchText.length > 0 && !searchResults?.Users?.length && !searchResults?.Zones?.length"
      position="bottom no-padding-top"
    >
      <h6 class="search_no-result_header">
        {{ $t('Components.Search.no_results_header') }}
      </h6>
      <p class="search_no-result_text">
        {{ $t('Components.Search.no_results_text') }}
      </p>
    </Card>
  </div>
</template>

<script>
import Search from '@/Services/Search';
import Card from '@/components/card/card.vue';
import SearchResult from './search.result.vue';

export default {
  components: {
    SearchResult,
    Card,
  },
  props: {
    resources: {
      default: true,
      type: Boolean,
    },
    users: {
      default: true,
      type: Boolean,
    },
    results: {
      default: null,
      type: Array,
    },
  },
  data() {
    return {
      Search,
    };
  },
  computed: {
    searchResults() {
      return this.results || Search.searchResults;
    },
  },
  methods: {
    bubbleUp(e) {
      this.$emit('onResultClick', e);
    },
    pinResource(e) {
      this.$emit('pinResource', e);
    },
    shouldShow(result) {
      if (this.resources && this.users && (result.Username || result.Zid)) return true;
      if (this.resources && result.Zid) return true;
      if (this.users && result.Username) return true;
      return false;
    },
  },

};
</script>
<style lang="scss" scoped>
@import '../style/search.scss';

</style>
