<template>
  <!-- Result container -->
  <div
    class="search-result__container"
  >
    <!-- USER item -->
    <div
      v-if="isUser"
      class="search-result__container-user"
    >
      <PersonSmall
        :user="personProps.user"
        :action="personProps.action"
        :avatar-size="personProps.size"
        @onPersonAction="onPersonAction"
        @contentClicked="showDetails"
      />
    </div>
    <!-- RESOURCE item -->
    <div
      v-else
      class="search-result__container-resource"
    >
      <ResourceSmall
        :src="resourceIcon"
        :name="result.Name"
        :status="occupiedMessage"
        :secondary-content="getSecondaryContent()"
        @onResourceAction="onResourceAction"
        @contentClicked="showDetails"
      />
    </div>
  </div>
</template>

<script>
import CompanyService from '@/Services/Company/company.service';

import BookingService from '@/Services/Bookings/bookings.service';
import PersonSmall from '@/components/globals/Person/PersonSmall.vue';
import ResourceSmall from '@/components/globals/Resource/ResourceSmall.vue';

export default {
  components: {
    PersonSmall,
    ResourceSmall,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['result'],
  data() {
    return {
      visible: false,
      bookings: null,
      zone: null,
    };
  },
  computed: {
    status() { return this.result.Status.Type; },
    statusIndicator() { return CompanyService.getUserStatusFromType(this.result.Status.Type)?.BackgroundColor || null; },
    statusMessage() { return CompanyService.getUserStatusFromType(this.result.Status.Type).Title || ' '; },
    resourceIcon() { return CompanyService.getResourceIconFromType(this.result.Type); },
    id() { return this.result.Username || this.result.Zid; },
    activeBookings() { return this.bookings && this.bookings.length; },
    hasActiveBooking() { return this.activeBookings && this.bookings[0].From < Date.now(); },
    isUser() { return this.result.Username; },
    isBooked() { return this.bookings && this.bookings.length && this.bookings[0].From - Date.now() < (1000 * 60 * 15); },
    personProps() {
      return {
        user: this.result,
        action: this.hasActiveBooking ? 'map-marker-alt' : null,
        size: '42',
      };
    },
    occupiedMessage() {
      if (this.isBooked) {
        return this.result.Occupied ? 'Occupied' : 'Booked';
      } return 'Available';
    },
    zid() { if (this.isUser) return this.bookings[0].Zid; return this.result.Zid; },
  },
  created() {
    if (!this.isUser) { this.zone = CompanyService.getZoneByZid(this.zid); }
    this.loadBookings();
  },
  methods: {
    find() {
      // TODO: implement find.
      console.log('IMPLEMENT FIND');
    },

    onPersonAction() {
      if (this.hasActiveBooking) {
        this.onResourceAction();
      }
    },

    onResourceAction() {
      this.setupResult();
      this.$emit('pinResource', this.result);
    },

    loadBookings() {
      if (this.result.Username) {
        this.bookings = BookingService.getBookingsByUsername(this.result.Username);
      } else {
        this.bookings = BookingService.getBookingsByZid(this.result.Zid);
      }
    },

    getSecondaryContent() {
      if (this.zone?.Bookable) {
        return { icon: 'clock', text: this.occupiedMessage };
      }
      return { icon: 'map-marker-alt', text: this.getZoneLocation() };
    },

    getZoneLocation() {
      return CompanyService.getLocationByParent(this.zone?.Parent, this.result?.Zid);
    },

    setupResult() {
      this.result.isUser = !!this.isUser;
      this.result.bookings = this.bookings;
      if (!this.isUser) {
        this.result.resourceIcon = this.resourceIcon;
        this.result.occupiedMessage = this.occupiedMessage;
        this.result.bookable = this.zone.Bookable;
      }
    },

    showDetails() {
      this.setupResult();
      this.$emit('onResultClick', this.result);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../style/search.scss';
.search-result__action {
  flex: 0 0 30px;
  color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-result__container {
  padding: 1rem 0;
  cursor: pointer;
  color: var(--primary-font-color);
  svg {
    max-width: 26px;
    max-height: 26px;
  }
}

// .search-result__container-user {
// }
.search-result__container-resource {
  display: flex;
  flex: 1 1 auto;
}

.search-result__icon {
  flex: 0 0 45px;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.search-result__content {
  flex: 1 1 auto;
  margin-left: 0.5rem;

  h6 {
    margin-bottom: 0;
  }
}

</style>
