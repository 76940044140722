<template>
  <div class="search-wrapper">
    <Header
      class="flex"
      :title="$t('Components.Search.title')"
    >
      <h5 class="header-title">
        {{ $t('Components.Search.title') }}
      </h5>
    </Header>
    <Card class="search_card">
      <SearchField />
    </Card>
    <div class="scrollable search_results_container">
      <SearchResults
        :results="recentlyClickedResults?.reverse()"
        @onResultClick="showDetails"
        @pinResource="pinResource"
      />
    </div>
  </div>
</template>

<script>
import LocationController from '@/views/location.selection/location.selection.controller';
import MapController from '@/classes/map/map.controller';
import QueryMixin from '@/mixins/query.mixin';

import CompanyState from '@/singletons/company.state.singleton';
import CompanyService from '@/Services/Company/company.service';
import SearchResults from '@/views/search/components/search.results.vue';
import SearchField from '@/views/search/components/search.field.vue';
import UserState from '@/singletons/user.state.singleton';
import BookingService from '@/Services/Bookings/bookings.service';
import Search from '@/Services/Search';
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';
import SearchResultState from '@/singletons/search.result.state';

export default {
  components: {
    Card,
    SearchResults,
    SearchField,
    Header,
  },
  mixins: [QueryMixin],
  data() {
    return {
      navigation: 'home',
      details: null,
    };
  },
  computed: {
    name() { return this.details !== null ? this.details.Name : ''; },
    recentlyClickedResults() {
      if (!UserState.user?.AbstractUser || Search.searchText) return null;
      const r = SearchResultState.recentFindResults?.map((it) => it.obj);
      const users = r.filter((obj) => obj.Username);
      const zones = r.filter((obj) => !obj.Username);
      const searchResults = {};
      searchResults.Users = users;
      searchResults.Zones = zones;
      return searchResults;
    },
  },
  mounted() {
    Search.fetchNewSearchOptions();
    BookingService.getBookingsOfDay(new Date());
    SearchResultState.clearOldFindResults();
  },
  async created() {
    if (!CompanyState.companyTree) { await CompanyService.getCompanies(); }
    if (!CompanyState.zones.length) { await CompanyService.getZones(); }
  },
  methods: {
    async showDetails(e) {
      this.details = e;
      if (e.isUser) {
        this.$router.push(`/search/details/${e.Username}`);
      } else {
        this.pinResource(e);
        this.$router.push(`/book/${e.Type}/${e.Zid}?ignoreType=true`);
      }
      if (UserState.user?.AbstractUser) {
        SearchResultState.storeClickedFindResult(e);
      }
    },
    async pinResource(e) {
      const zone = CompanyState.zones.find((z) => z.Zid === e.Zid);
      const floor = CompanyState.zones.find((z) => z.Zid === zone.ParentZone);
      const currentFloor = UserState.selectedLocation;
      if (floor.Zid !== currentFloor.Zid) {
        LocationController.setLocationByFloorZid(floor.Zid);
      }
      this.replaceQuery({ zid: zone.Zid });
      /**
       * Since setLocation emits events that we need to be fired, we need an await to wait for the stack to be executed
       * or we get navigation cancelled when we swap floor...
       * https://stackoverflow.com/questions/55262996/does-awaiting-a-non-promise-have-any-detectable-effect
       */
      await new Promise((resolve) => resolve(true));

      MapController.showMapMarker(zone, true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style/search.scss';
.search_card {
  margin-bottom: 1rem;
  padding: 0.5rem 0rem;
}
.search_results_container {
  padding-bottom: 2rem;
}
</style>
