<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for -->
  <div class="detail-view__container">
    <Header
      class="flex"
      :back="true"
      :text="$t('Components.Search.title')"
      route="/search"
      :title="$t('Components.Search.title')"
    >
      <h5 class="header-title">
        {{ user.Name }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card class="user_card">
        <div class="detail-view__user search-section">
          <PersonSmall
            :user="user"
            :avatar-size="'48'"
            :action="null"
          />
        </div>
      </Card>

      <Card
        v-if="activeBookings.length"
        position="bottom no-padding-top"
        :header="$t('Components.Search.active_booking')"
      >
        <div
          v-for="(booking) in activeBookings"
          :key="booking.Bid"
          class="detail-view__user-booking search-section"
          @click="find(booking.Zid)"
        >
          <div class="detail-view_icon_and_text">
            <RoundResourceIcon
              :src="getResourceIcon(booking)"
              :type="`resource ${bookingState(booking)}`"
            />
            <div class="detail-view__user-booking--content">
              <h6
                v-if="globalUserIsOwnerOrAttende(booking)"
                class="normal-semi"
              >
                {{ booking.Subject }}
              </h6>
              <h6
                v-else
                class="normal-semi"
              >
                {{ $t('Components.Search.meeting') }}
              </h6>
              <h6 class="small-regular">
                {{ getZoneNameByZid(booking.Zid) }},  {{ getTimeString(booking) }}
              </h6>
            </div>
          </div>
          <div
            class="primary-color-icon noselect"
          >
            <svg-icon
              src="/icons/map_navigation_pin.svg"
            />
          </div>
        </div>
      </Card>

      <Card
        v-if="upcomingBookings.length"
        position="bottom no-padding-top"
        :header="$t('Components.Search.upcoming_booking')"
      >
        <div
          v-for="(booking) in upcomingBookings"
          :key="booking.Bid"
          class="detail-view__user-booking search-section"
          @click="find(booking.Zid)"
        >
          <div class="detail-view_icon_and_text">
            <RoundResourceIcon
              :src="getResourceIcon(booking)"
              :type="`resource ${bookingState(booking)}`"
            />
            <div class="detail-view__user-booking--content">
              <h6
                v-if="globalUserIsOwnerOrAttende(booking)"
                class="normal-semi"
              >
                {{ booking.Subject }}
              </h6>
              <h6
                v-else
                class="normal-semi"
              >
                {{ $t('Components.Search.meeting') }}
              </h6>
              <h6 class="small-regular">
                {{ getZoneNameByZid(booking.Zid) }},  {{ getTimeString(booking) }}
              </h6>
            </div>
          </div>
          <div
            class="primary-color-icon noselect"
          >
            <svg-icon
              src="/icons/map_navigation_pin.svg"
            />
          </div>
        </div>
      </Card>

      <Card
        v-if="connections.length"
        position="bottom no-padding-top"
        :header="$t('Components.Search.connections')"
      >
        <div
          v-for="(conn) in connections"
          :key="conn.Zid"
          class="detail-view__user-booking search-section"
          @click="find(conn.Zid)"
        >
          <div class="detail-view_icon_and_text">
            <RoundResourceIcon
              :src="getResourceIcon(getLocationByZoneId(conn.Zid))"
              type="connection"
            />
            <div class="detail-view__user-booking--content">
              <h6 class="normal-semi">
                {{ getConnectionTitle(getLocationByZoneId(conn.Zid)?.Type) }}
              </h6>
              <h6 class="small-regular">
                {{ getZoneNameByZid(conn.Zid) }},  {{ getConnectionTimeString(conn) }}
              </h6>
            </div>
          </div>
          <div
            class="primary-color-icon noselect"
          >
            <svg-icon
              src="/icons/map_navigation_pin.svg"
            />
          </div>
        </div>
      </Card>

      <Card
        v-if="!connections.length && !activeBookings.length && !upcomingBookings.length"
        position="bottom no-padding-top"
      >
        <h6 class="search_no-result_header">
          {{ $t('Components.Search.no_bookings_header') }}
        </h6>
        <p class="search_no-result_text">
          {{ $t('Components.Search.no_bookings_text', { name: user.Name }) }}
        </p>
      </Card>
    </div>
  </div>
</template>

<script>
import ExtendedDate from '@/classes/extended.date.class';
import CompanyState from '@/singletons/company.state.singleton';
import ZoneState from '@/singletons/zone.state.singleton';
import CompanyService from '@/Services/Company/company.service';
import UserState from '@/singletons/user.state.singleton';
import LocationController from '@/views/location.selection/location.selection.controller';
import MapController from '@/classes/map/map.controller';
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import PhoneService from '@/Services/Phone/phone.service';
import PersonSmall from '@/components/globals/Person/PersonSmall.vue';
import Search from '@/Services/Search';
import BookingService from '@/Services/Bookings/bookings.service';
import QueryMixin from '../../../mixins/query.mixin';

export default {
  components: {
    PersonSmall,
    RoundResourceIcon,
    Header,
    Card,
  },
  mixins: [QueryMixin],
  // eslint-disable-next-line vue/require-prop-types
  data() {
    return {
      hasLoaded: false,
      user: {
        Name: '',
        Username: '',
      },
      realmState: null,
      secondaryContent: {
        text: '',
        icon: 'envelope',
      },
      showAll: false,
      thirtyMinMillis: 30 * 60 * 1000,
      floor: null,
    };
  },
  computed: {
    haveActiveBooking() {
      let haveActive = false;
      this.userBookings.forEach((booking) => {
        if (this.isBookingToday(booking)) {
          haveActive = true;
        }
      });
      return haveActive;
    },
    userBookings() {
      if (!this.user?.Username) return [];
      const bookings = BookingService.getBookingsByUsername(this.user?.Username);
      return bookings.filter((b) => this.isBookingToday(b));
    },
    activeBookings() {
      const activeBookings = this.userBookings.filter((b) => this.isActiveBooking(b));
      return activeBookings;
    },
    upcomingBookings() {
      const upcomingBookings = this.userBookings.filter((b) => this.isUpcomingBooking(b));
      return upcomingBookings;
    },
    connections() {
      return ZoneState?.realmState.filter((user) => user?.Username === this?.user?.Username) || [];
    },
  },
  async created() {
    if (!CompanyState.companyTree) {
      await CompanyService.fetchCompanies();
    }
    this.user = await Search.getColleague(this.$route.params.id);
    this.secondaryContent.text = this.user.Username;
    this.hasLoaded = true;
  },
  methods: {
    globalUserIsOwnerOrAttende(booking) {
      const userNames = [booking.Owner];
      const att = booking.Attendees?.map((it) => it.Username);
      userNames.push(...att);
      const found = userNames.find((it) => it === UserState.user.Username);
      return !!found;
    },
    bookingState(booking) {
      return this.bookingStatus(booking) ? 'occupied' : 'away';
    },
    getConnectionTitle(type) {
      switch (type) {
        case 'DESK': return this.$t('Components.Search.connected_to_desk');
        case 'LOCKER': return this.$t('Components.Search.connected_to_lock');
        default: return this.$t('Components.Search.connected_to_connection');
      }
    },
    connState(conn) {
      return conn.Active ? 'occupied' : 'away';
    },
    async find(_zid) {
      const booking = this.userBookings.find((b) => this.isBookingToday(b));
      const zid = _zid || booking.Zid;
      const zone = CompanyState.zones.find((z) => z.Zid === zid);
      if (zone.ParentZone !== UserState.selectedLocation.Zid) {
        await LocationController.setLocationByFloorZid(zone.ParentZone);
      }
      if (PhoneService.isInPhoneApp()) {
        this.$router.push({ name: 'map', query: { zid: zone.Zid } });
      } else {
        this.replaceQuery({ zid: zone.Zid });
        MapController.showMapMarker(zone, true);
      }
    },
    isBookingToday(booking) {
      return booking.Until > Date.now() && new ExtendedDate(booking.From).isToday();
    },
    isActiveBooking(booking) {
      const now = new Date();
      const bookingStart = new Date(booking.From);
      const bookingEnd = new Date(booking.Until);
      return bookingStart <= now && bookingEnd >= now;
    },
    isUpcomingBooking(booking) {
      const now = new Date();
      const bookingStart = new Date(booking.From);
      return bookingStart.toDateString() === now.toDateString() && bookingStart > now;
    },
    bookingStatus(booking) {
      return booking.From < Date.now();
    },
    getResourceIcon(booking) {
      return CompanyService.getResourceIconFromType(booking?.ZoneType || booking?.Type);
    },
    getZoneNameByZid(zid) {
      return CompanyState.zones.find((zone) => zid === zone.Zid)?.Name;
    },
    getLocationByParent(booking) {
      return CompanyService.getLocationByParent(booking.Parent, booking.Zid);
    },
    getLocationByZoneId(zid) {
      return CompanyService.getZoneByZid(zid);
    },
    getZoneByZid(zid) {
      return CompanyState.zones.find((zone) => zid === zone.Zid);
    },
    getConnectionTimeString(conn) {
      const from = new ExtendedDate(conn.Created);
      return this.$t('Components.Search.search_details_from', { time: from.localeTimeString() });
    },
    getTimeString(booking) {
      const from = new ExtendedDate(booking.From);
      const until = new ExtendedDate(booking.Until);
      return `${from.localeTimeString()} - ${until.localeTimeString()}`;
    },
  },
};
</script>

<style lang="scss">
@import '../style/search.scss';

.user_card {
  margin-bottom: 1rem;
  padding: 0.5rem 0rem;
}
.detail-view__user-booking {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  .detail-view_icon_and_text {
    display: flex;
    margin-bottom: 1rem;
    gap: 1.75rem;
  }
    &:last-child {
      .detail-view_icon_and_text {
        margin-bottom: 0;
      }
    }
    .primary-color-icon {
    margin: auto 0;
    svg{
      fill: var(--primary-color);
      vertical-align: top;
    }
  }
}

.is_toggle {
  display: none;
  .show {
    display: flex;
  }
  .hide {
    display: none;
  }
}

.accordion__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail-view__user-booking--content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h6 {
    margin: 0px;
  }
}

.detail-view__show-location {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.5rem;
}

</style>
